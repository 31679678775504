@use '@angular/material' as mat;
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/animations/scale.css';

$skeleton-element-color: rgba(220, 229, 234, 0.5);
$skeleton-square-height: 100%;
$skeleton-line-height: 100%;
$skeleton-loading-animation-time: 1.3s !default;
$skeleton-margin-standart: 40px !default;

@import "skeleton-screen-css/dist/index.scss";

body {
  /* colors variables*/
  $scp-theme-color-01: #577079;
  $scp-theme-color-02: #60a120;
  $scp-theme-color-03: #ff7922;
  $scp-theme-color-04: #94b0bf;
  $scp-theme-color-05: #4d6cad;
  $scp-theme-color-06: #ffffff;
  $scp-theme-color-07: #fafbfc;
  $scp-theme-color-09: #fff5ef;
  $scp-theme-color-10: #7fb929;
  $scp-theme-color-11: #b2c9d6;
  $scp-theme-color-12: #ff9550;
  $scp-theme-color-13: #f4f7f9;
  $scp-theme-color-14: #ff3d3d;
  $scp-theme-color-15: #4c6aaa;
  $scp-theme-color-16: #F86523;

  --swiper-theme-color: #{$scp-theme-color-02};

  .swiper-pagination-bullet {
    background: var(--scp-theme-color-06);
    opacity: 0.75;
  }

  .swiper-pagination-bullet-active {
    background: var(--scp-theme-color-02);
    border: 1px solid var(--scp-theme-color-06);
    opacity: 1;
  }

  /* colors */
  --scp-theme-color-01: #{$scp-theme-color-01};
  --scp-theme-color-02: #{$scp-theme-color-02};
  --scp-theme-color-03: #{$scp-theme-color-03};
  --scp-theme-color-04: #{$scp-theme-color-04};
  --scp-theme-color-05: #{$scp-theme-color-05};
  --scp-theme-color-06: #{$scp-theme-color-06};
  --scp-theme-color-07: #{$scp-theme-color-07};
  --scp-theme-color-09: #{$scp-theme-color-09};
  --scp-theme-color-10: #{$scp-theme-color-10};
  --scp-theme-color-11: #{$scp-theme-color-11};
  --scp-theme-color-12: #{$scp-theme-color-12};
  --scp-theme-color-13: #{$scp-theme-color-13};
  --scp-theme-color-14: #{$scp-theme-color-14};
  --scp-theme-color-15: #{$scp-theme-color-15};
  --scp-theme-color-16: #{$scp-theme-color-16};

  .wpwl-control, .wpwl-container {
    height: 60px !important;
    width: 100% !important;

    .wpwl-form {
      margin: 0;
      max-width: 100%;
    }

    button {
      width: 100%;
      height: 60px;
    }
  }

  // color variables
  --scp-theme-color-01-variable-01: #{rgba(
      red($scp-theme-color-04),
      green($scp-theme-color-04),
      blue($scp-theme-color-04),
      0.1
    )};

  --scp-theme-color-01-variable-04: #{rgba(
      red($scp-theme-color-01),
      green($scp-theme-color-01),
      blue($scp-theme-color-01),
      0.7
    )};

  --scp-theme-color-01-variable-02: #{rgba(
      red($scp-theme-color-04),
      green($scp-theme-color-04),
      blue($scp-theme-color-04),
      0.2
    )};
  --scp-theme-color-01-variable-03: #{rgba(
      red($scp-theme-color-04),
      green($scp-theme-color-04),
      blue($scp-theme-color-04),
      0.5
    )};
  --scp-theme-color-04-variable-04: #{rgba(
      red($scp-theme-color-04),
      green($scp-theme-color-04),
      blue($scp-theme-color-04),
      0.3
    )};

  --scp-dashboard-menu-active-color: #{rgba(
      red($scp-theme-color-01),
      green($scp-theme-color-01),
      blue($scp-theme-color-01),
      0.08
    )};

  --scp-button-type-6-background: #{rgba(
      red($scp-theme-color-02),
      green($scp-theme-color-02),
      blue($scp-theme-color-02),
      0.2
    )};

  --scp-button-type-7-background: #{rgba(
      red($scp-theme-color-02),
      green($scp-theme-color-02),
      blue($scp-theme-color-02),
      0.6
    )};

  --scp-button-type-8-background: #{rgba(
      red($scp-theme-color-02),
      green($scp-theme-color-02),
      blue($scp-theme-color-02),
      0.1
    )};

  --scp-footer: #{rgba(red($scp-theme-color-04), green($scp-theme-color-04), blue($scp-theme-color-04), 0.07)};

  /* label */
  --scp-label-color: var(--scp-theme-color-01);
  /* banner */
  --scp-balance-background-color: var(--scp-theme-color-07);
  --scp-balance-background-color-2: var(--scp-theme-color-06);
  --scp-balance-cash-color: var(--scp-theme-color-02);
  --scp-balance-bonus-color: var(--scp-theme-color-05);
  --scp-balance-withdraw-value: var(--scp-theme-color-03);
  --scp-balance-customer-background-color: var(--scp-theme-color-07);
  --scp-balance-customer-name-color: var(--scp-theme-color-01);
  --scp-balance-customer-phone-color: var(--scp-theme-color-05);
  /* bonus-program-offer */
  /* bonus-programs-offers */
  /* card */
  --scp-card-remove-border-color: var(--scp-theme-color-03);
  --scp-card-remove-background-color: var(--scp-theme-color-09);
  /* dashboard */
  --scp-dashboard-color: var(--scp-theme-color-01);
  --scp-dashboard-background-color: var(--scp-theme-color-06);
  --scp-dashboard-menu-divider-border: solid 1px var(--scp-theme-color-01-variable-03);
  --scp-dashboard-menu-backgroud-color: var(--scp-theme-color-07);
  --scp-dashboard-menu-color: var(--scp-theme-color-01);
  --scp-dashboard-menu-active-backgroud-color: var(--scp-theme-color-06);
  --scp-dashboard-menu-arrow-color: var(--scp-theme-color-04);
  --scp-dashboard-sub-menu-color: var(--scp-theme-color-01);
  /* atm */
  --scp-atm-details-background-color: var(--scp-theme-color-07);
  --scp-atm-step-color: var(--scp-theme-color-01);
  --scp-atm-step-title-color: var(--scp-theme-color-04);
  /* banks */
  --scp-bank-background-color: var(--scp-theme-color-07);
  --scp-bank-step-color: var(--scp-theme-color-01);
  --scp-bank-step-title-color: var(--scp-theme-color-04);
  /* banks-details */
  --scp-bank-details-background-color: var(--scp-theme-color-01-variable-01);
  --scp-bank-details-name-color: var(--scp-theme-color-01);
  --scp-bank-details-color: var(--scp-theme-color-04);
  --scp-bank-details-account-color: var(--scp-theme-color-01);
  /* cards */
  /* cards-cvv-info */
  --scp-cvv-info-title-color: var(--scp-theme-color-01);
  --scp-cvv-info-border: solid 2px var(--scp-theme-color-03);
  --scp-cvv-info-color: var(--scp-theme-color-02);
  --scp-cvv-info-card-background-color: var(--scp-theme-color-01-variable-01);
  /* cards-managements */
  /* cards-processing */
  --scp-deposit-cards-processing-info-background-color: var(--scp-theme-color-02);
  /* confirm */
  --scp-deposit-confirm-amount-color: var(--scp-theme-color-02);
  /* eft */
  --scp-eft-details-background-color: var(--scp-theme-color-07);
  --scp-eft-step-color: var(--scp-theme-color-01);
  --scp-eft-step-title-color: var(--scp-theme-color-04);
  /* one-voucher */
  --scp-one-voucher-info-background-color: var(--scp-theme-color-02);
  /* one-voucher-partners */
  --scp-one-voucher-partners-color: var(--scp-theme-color-01);
  /* ott-voucher */
  --scp-ott-voucher-info-background-color: var(--scp-theme-color-02);
  /* ott-voucher-partners */
  --scp-ott-voucher-partners-color: var(--scp-theme-color-01);
  /* ozow */
  /* sid */
  /* snap-scan */
  --scp-snap-scan-amount-color: var(--scp-theme-color-02);
  /* zapper */
  --scp-deposit-zapper-background-color: var(--scp-theme-color-01-variable-01);
  /* deposits */
  /* dialog-question */
  /* forgot-password */
  /* forgot-password-otp */
  /* header */
  --scp-header-color: var(--scp-theme-color-06);
  --scp-header-shadow: 0 3px 5px 0 rgba(87, 112, 121, 0.1);
  --scp-header-divider-color: rgba(87, 112, 121, 0.08);
  --scp-header-link-color: var(--scp-theme-color-01);
  --scp-header-profile-color: var(--scp-theme-color-03);
  --scp-header-actions-color: var(--scp-theme-color-01);
  /* header-cookies */
  --header-cookies-background-color: var(--scp-theme-color-06);
  --header-cookies-shadow: 0 -5px 5px 0 rgba(114, 134, 145, 0.15);
  /* landing */
  /* loader */
  --scp-loader-background-color: #294f61;
  /* menu-item */
  --scp-menu-item-title-color: var(--scp-theme-color-01);
  --scp-menu-item-counter-color: var(--scp-theme-color-06);
  --scp-menu-item-counter-background-color: var(--scp-theme-color-02);
  /* notification */
  --scp-notification-shadow: 0 10px 10px 0 rgba(100, 123, 133, 0.2);
  --scp-notification-error-background-color: var(--scp-theme-color-09);
  --scp-notification-error-color: var(--scp-theme-color-03);
  --scp-notification-success-background-color: #f4ffe5;
  --scp-notification-success-color: var(--scp-theme-color-02);
  /* profile */
  /* refer-a-friend */
  /* sign-in */
  /* sign-in-password */
  --scp-forgot-password-link-color: var(--scp-theme-color-02);
  /* sign-up-id */
  /* statements */
  /* title */
  --scp-title-color: var(--scp-theme-color-01);
  /* wagers */
  /* withdrawal-list-item */
  --scp-list-item-title-color: var(--scp-theme-color-01);
  --scp-list-item-description-color: var(--scp-theme-color-04);
  /* withdrawal-request */
  /* withdrawal-requests */
  /* withdrawal-send-to-my-bank */
  /* withdrawal-send-to-my-bank-info */
  /* withdrawal-standard-bank */
  --scp-withdrawal-standard-bank-amount-color: var(--scp-theme-color-02);
  /* withdrawal-standard-bank-info */
  --scp-withdrawals-bank-info-name-color: var(--scp-theme-color-01);
  --scp-withdrawals-bank-info-description-color: var(--scp-theme-color-04);

  /* withdrawals */

  /* padding*/
  --scp-padding: 16px;
  /* general */
  --scp-background-color: var(--scp-theme-color-06);
  --scp-second-background-color: var(--scp-theme-color-07);
  /* border */
  --scp-border: dotted 1px var(--scp-theme-color-01-variable-03);
  --scp-border-2: solid 1px var(--scp-theme-color-01-variable-03);
  --scp-border-3: solid 2px var(--scp-theme-color-01-variable-03);
  --scp-border-radius: 4px;
  /* buttons */
  --scp-butto-shadow: 0 2px 8px 0 var(--scp-theme-color-01-variable-02);
  --scp-button-type-1-color: var(--scp-theme-color-06);
  --scp-button-type-1-background: var(--scp-theme-color-02);
  --scp-button-type-1-background-hover: var(--scp-theme-color-10);
  --scp-button-type-2-color: var(--scp-theme-color-06);
  --scp-button-type-2-background: var(--scp-theme-color-04);
  --scp-button-type-2-background-hover: var(--scp-theme-color-11);
  --scp-button-type-3-color: var(--scp-theme-color-06);
  --scp-button-type-3-background: var(--scp-theme-color-03);
  --scp-button-type-3-background-hover: var(--scp-theme-color-12);
  --scp-button-type-4-color: var(--scp-theme-color-04);
  --scp-button-type-4-background: var(--scp-theme-color-06);
  --scp-button-type-4-background-hover: var(--scp-theme-color-13);
  --scp-button-type-5-color: var(--scp-theme-color-01);
  --scp-button-type-5-background: var(--scp-theme-color-06);
  --scp-button-type-5-background-hover: var(--scp-theme-color-13);
  --scp-button-type-16-background: var(--scp-theme-color-16);
  /* inputs */
  --scp-input-background-color: var(--scp-theme-color-01-variable-01);
  --scp-input-color: var(--scp-theme-color-01);
  --scp-input-disabled-color: #{rgba(
      red($scp-theme-color-01),
      green($scp-theme-color-01),
      blue($scp-theme-color-01),
      0.5
    )};
  --scp-input-placeholder-color: var(--scp-theme-color-04);
  --scp-input-border-color: var(--scp-theme-color-01-variable-03);
  --scp-input-error-color: var(--scp-theme-color-03);
  --scp-input-error-background: rgba(239, 99, 51, 0.07);
  /* hover */
  --scp-section-item-hover: var(--scp-theme-color-13);

  //* back btn */
  --scp-back-btn-background-color: #f7fafb;
  --scp-back-btn-box-shadow: 0 3px 6px 0 rgba(87, 112, 121, 0.1);

  --mdc-theme-text-primary-on-background: var(--scp-theme-color-01);
  --mdc-theme-primary: #60a120;
  --mdc-switch-selected-pressed-handle-color: #60a120;
  --mdc-switch-selected-hover-handle-color: #60a120;
  --mdc-switch-selected-focus-handle-color: #60a120;

  --mdc-switch-selected-pressed-track-color: #cfe3bc;
  --mdc-switch-selected-track-color: #cfe3bc;
  --mdc-switch-selected-focus-track-color: #cfe3bc;
  --mdc-switch-selected-hover-track-color: #cfe3bc;


  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: white !important;
  }

  --mdc-typography-body1-letter-spacing: normal;

  @include mat.core();

  $mat-primary: (
    main: #60a120,
    lighter: #cfe3bc,
    darker: #448513,
    200: #60a120,
    // For slide toggle,
    contrast:
    (
      main: white,
      lighter: rgba(black, 0.87),
      darker: white,
    ),
  );

  $mat-accent: (
    main: $scp-theme-color-03,
    lighter: #cfe3bc,
    darker: #448513,
    200: #60a120,
    // For slide toggle,
    contrast:
    (
      main: white,
      lighter: rgba(black, 0.87),
      darker: white,
    ),
  );

  $theme: mat.define-light-theme((
    color: (
      primary: mat.define-palette($mat-primary, main, lighter, darker),
      accent: mat.define-palette($mat-accent, main, lighter, darker),
      warn: mat.define-palette($mat-primary, main, lighter, darker),
      //  accent: mat.define-palette(mat.$pink-palette),
      //  warn: mat.define-palette(mat.$red-palette)
    ),
    typography: mat.define-typography-config($font-family: 'Open Sans'),
  ));

  @include mat.core-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.button-theme($theme);
  @include mat.icon-button-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.slide-toggle-color($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.bottom-sheet-theme($theme);
  @include mat.radio-theme($theme);

  .mat-mdc-menu-content {
    padding: 0;
    color: var(--scp-theme-color-01);
  }

  .mat-mdc-menu-item {
    color: var(--scp-theme-color-01);
    font-weight: 600;
    min-height: 48px;
    height: auto;
    line-height: 22px;
    white-space: break-spaces;
    text-overflow: unset;
    display: flex !important;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;

    .mat-mdc-menu-item-text {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: var(--scp-theme-color-01);
      display: flex !important;
      align-items: center;
    }
  }

  .mat-checkbox {
    label {
      white-space: pre-line;
      width: 100%;
    }

    .mat-checkbox-label {
      width: 100%;
      line-height: 18px;
    }
  }

  mat-checkbox.s {
    label {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: normal;
    }
  }

  .mdc-button__label {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 40px;
    height: 40px;
  }

  .mat-date-range-input-separator {
    color: var(--scp-theme-color-01);
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: var(--scp-theme-color-04-variable-04);
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: none;
    box-shadow: none;
    padding-right: 0;

    .mat-mdc-snack-bar-label {
      padding: 0;
    }
  }

  .mat-icon-button,
  .mat-fab,
  .mat-mini-fab {
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-button-wrapper {
      vertical-align: middle;
      display: inline-flex;
    }
  }

  .tippy-box[data-theme~=light-border] {
    opacity: 0.95;
    padding: 14px 14px 14px;
    border-width: 2px;
    border-color: var(--scp-theme-color-03);
    max-width: 290px;
    width: 100%;
    min-width: 200px;
    box-shadow: 0 3px 10px 0 rgba(0, 25, 30, 0.15);
    cursor: default;

    .tippy-content {
      padding: 0;
    }
  }

  [data-tippy-root] {
    pointer-events: all;
  }

  .tippy-box[data-theme~=light-border][data-placement^=top] > .tippy-arrow::after {
    border-top-color: var(--scp-theme-color-03);
  }

  .tippy-box[data-theme~=light-border][data-placement^=top] > .tippy-arrow::before {
    bottom: -5px;
    border-top-color: var(--scp-theme-color-06);
  }

  .tippy-box[data-theme~=light-border][data-placement^=bottom] > .tippy-arrow:after {
    border-bottom-color: var(--scp-theme-color-03);
  }

  .tippy-box[data-theme~=light-border][data-placement^=bottom] > .tippy-arrow:before {
    top: -5px;
    border-bottom-color: var(--scp-theme-color-06);
  }

  .tippy-box[data-theme~=light-border][data-placement^=left] > .tippy-arrow::after {
    border-left-color: var(--scp-theme-color-03);
  }

  .tippy-box[data-theme~=light-border][data-placement^=left] > .tippy-arrow::before {
    right: -5px;
    border-left-color: var(--scp-theme-color-06);
  }

  .tippy-box[data-theme~=light-border][data-placement^=right] > .tippy-arrow::after {
    border-right-color: var(--scp-theme-color-03);
  }

  .tippy-box[data-theme~=light-border][data-placement^=right] > .tippy-arrow::before {
    left: -5px;
    border-right-color: var(--scp-theme-color-06);
  }

  .rule {
    font-weight: 400;
  }

  .inline-link {
    color: var(--scp-theme-color-02);
    white-space: nowrap;
    text-decoration: underline;
  }
}

.mat-menu-panel {
  min-height: 48px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-menu-panel-title {
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-height: 32px;
  font-size: 18px;
  color: var(--scp-theme-color-02);

  &.small {
    font-size: 15px;
  }
}

.vh-90 {
  max-height: 800px !important;
  height: 90%;

  mat-bottom-sheet-container {
    padding: 0;
    min-width: calc(100vw - 20px);
    margin-left: 10px;
    margin-right: 10px;
    max-height: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.mat-date-range-input-container {
  width: 100%;
}

.mat-mdc-list-base {
  padding-top: 0;
  padding-bottom: 0;
}

.mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: visible !important;
}

.mdc-list-item__content {
  overflow: visible !important;
}

mat-option {
  letter-spacing: normal;
}

.mat-mdc-option.mdc-list-item--disabled {
  .mdc-list-item__primary-text {
    opacity: 1 !important;
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0 !important;
  border-radius: 15px !important;
  --mdc-menu-max-width: 100%;

  .scroll {
    height: 100%;
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.scp-search-autocomplete {
  .mat-mdc-option.mdc-list-item--disabled {
    opacity: 1;
  }
}

.cms-link {
  display: inline-block;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  box-shadow: var(--scp-button-shadow);
  color: var(--scp-theme-color-06) !important;
  font-weight: 600;
  background-color: var(--scp-theme-color-03);
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  overflow: hidden;
  padding: 9px 18px;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .3s, opacity 0.5s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
  }
}

.scp-search-results-overlay {
  bottom: 80px;

  @media (min-width: 767px) {
    bottom: 16px;
  }
}
